import React from 'react'
import { NavLink } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import TextTransition, { presets } from 'react-text-transition'
import Work from './Work'
import { FaChrome, FaSearchDollar, FaAd, FaGoogle } from 'react-icons/fa'

const TEXTS = ['renovation', 'roofing', 'plumbing', 'landscaping', 'flooring', 'remodeling', 'electrician', 'septic', 'HVAC', 'painting', 'ecommerce'];

const services = [
    { title: "Marketing", description: "Marketing your business online is more than just having a website - we'll take you all the way.", icon: <FaSearchDollar /> },
    { title: "Websites", description: "New or redesigned websites built to convert visitors into leads with our decade+ of experience.", icon: <FaChrome /> },
    { title: "Ads", description: "We'll advertise to your target audience to get your offer in front of people who need it.", icon: <FaAd /> },
    { title: "SEO", description: "Customers usually click one of the first results on Google Search. We want that result to be you.", icon: <FaGoogle /> },
];

function Home(props) {
    const [index, setIndex] = React.useState(0);
    React.useEffect(() => {
        const intervalId = setInterval(
        () => setIndex((index) => index + 1),
        3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);
    return (
        <>
        <Row className='hero'>
          <Col>
            <span className="text-gradient-long">
              <h1 className='fs-1'>We deliver leads to <TextTransition inline={true} className='text-gradient-break' springConfig={presets.default}>{TEXTS[index % TEXTS.length]}</TextTransition> businesses <br className=' new-line-mobile' />like yours.</h1>
            </span>
            <h2 className='fw-light fs-4'>
              Our team of experts builds <span className='text-white'>websites</span> and <span className='text-white'>digital marketing solutions </span> for a wide range of organizations, 
              from local <span className='text-white'>home improvement businesses</span> to <span className='text-white'>e-commerce stores</span> to the <span className='text-white'>US Army</span>.
            </h2>
            <NavLink className='btn btn-light btn-main mt-4 glow' to="/contact">Free Consultation</NavLink>
          </Col>
        </Row>
        <Row className='section'>
          <Col>
          <h2 className='section-label text-gradient'>What We Do</h2>
            <p className='text-main'>
              <span className='text-white'>With Reavis Digital, you can expect more than just a website. </span> 
              We build long-term relationships with our clients to help businesses market themselves online and deliver new leads every month.
            </p>
            <h4 className='text-highlight drop-shadow'>We generate as much as <span className='text-white'>$20,000+</span> in estimated monthly revenue for our clients by capturing organic leads and sales with our SEO & web marketing strategies.</h4>
            <p className='text-main'>Interested in fueling your business by gaining more traction online? 
            It doesn't matter if your business is a newly-founded startup or a well-established legacy... <span className='text-white text-center'>We can bring in new leads.</span></p>
            
            <p className='text-main'>Here are a few of the many ways we enhance your online marketing and help your company grow:</p>
            </Col>
        </Row>
        <Row className='hero'>
            {services.map((service, i) => (
              <Col key={i} xl={3} lg={6} className='d-flex align-items-stretch px-0 pb-1'>
                <Card className=''>
                  <Card.Body className='p-0'>
                    <Card.Title className='text-gradient fw-bold'><span className='fs-4 me-2 text-light'>{service.icon}</span> {service.title}</Card.Title>
                    <Card.Text>
                      {service.description}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
            <NavLink className='btn btn-light btn-main' to="/services">Browse Services</NavLink>
        </Row>
        <Row className='section'>
          <Col>
              <h2 className='section-label text-gradient'>Projects</h2>
              <Work Location="home" api={props.dribbbleAPI} />
            <div className='text-center'>
              <NavLink className='btn btn-dark btn-main' to="/work">Our Portfolio</NavLink>
            </div>
          </Col>
        </Row>
        <Row className="hero">
          <Col>
            <h1 className='section-label text-gradient'>Work With Us</h1>
            <p className='lead'>
              Looking for an all-in-one web and marketing solution that can help take your business's online presence to the next level? 
              Look no further than our team at Reavis Digital!
            </p>
            <p>
              We specialize in creating beautiful, functional websites and marketing solutions for local businesses that are designed to help you achieve your goals and make your brand stand out in a crowded digital landscape. 
            </p>
            <p>
              If you're looking to grow your business and get new clients, with <span className="text-white">over a decade of experience</span> in the field, we have the expertise to deliver results.
            </p>
            <NavLink className='btn btn-light btn-main glow' to="/contact">Let's Chat</NavLink>
          </Col>
        </Row>
      </>
    );
  }
  
  export default Home;
  