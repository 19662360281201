import './App.css';
import React from 'react';
import AppRoutes from './routes';
import { BrowserRouter as Router} from 'react-router-dom'

import { Container } from 'react-bootstrap'
import NavbarComponent from './components/NavbarComponent'
import FooterComponent from './components/FooterComponent'
import { BsInstagram, BsLinkedin, BsYoutube, BsTiktok } from 'react-icons/bs'


const links = [
  { to: "/", name: "Home", },
  { to: "/services", name: "Services", },
  { to: "/work", name: "Portfolio", },
  { to: "/contact", name: "Contact", },
];

const socials = [
  // { to: "https://twitter.com/timothyreavis", name: <BsTwitter /> },
  { to: "https://www.youtube.com/@ReavisDigital", name: <BsYoutube /> },
  { to: "https://instagram.com/reavisdigital", name: <BsInstagram /> },
  { to: "https://www.tiktok.com/@reavisdigital", name: <BsTiktok /> },
  { to: "https://linkedin.com/in/timothyreavis", name: <BsLinkedin /> },
];

function App() {
  return (
    <Router>
      <Container>
        <NavbarComponent links={links} socials={socials} />
        <div className='container bg-dark mt-3'>
          <div className='ms-2 me-2 text-light'>
            <AppRoutes />
          </div>
        </div>
        <FooterComponent links={links} />
      </Container>
    </Router>
  );
}

export default App;
