import React, { useState, useEffect } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { FiArrowRight } from 'react-icons/fi';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom'

const Post = (props) => {
    const { title, link, thumbnail, content, excerpt, date, _links } = props.blogData;
    const [tagsData, setTagsData] = useState([])
    const [author, setAuthor] = useState([])
    let tagStr = '';
    tagStr += tagsData
      .map(x => x) 
      .join(',')
    useEffect(() => {
        fetch(props.api + "tags?include=" + tagStr)
            .then(response => response.json())
            .then(data => {
              setTagsData(data)
            })
        fetch(_links.author[0].href)
            .then(response => response.json())
            .then(data => {
              setAuthor(data)
            })
    }, [_links.author, props.api, tagStr])

    function truncateText(text, start, len) {
        return text.length > len ? text.slice(start, len) : text;
    }

    const Body = (data) => {
      let tag = <div></div>
      tag.innerHTML = data.data;
      return tag;
    }

    function htmlToText(block) {
        let tag = document.createElement('div');
        tag.innerHTML = block;
        return tag;
    }

    function convertDate(date) {
        let dateArray = date.slice(0, 10).split("-")
        let year = dateArray.shift();
        dateArray.push(year)
        return `${dateArray.join(".")}`;
    }

    return (
      <div>
            <div className="mb-2 me-auto">
              <h1 className='text-gradient pb-1'>{title.rendered}</h1>
              <span className="small">By {author.name} | {dateFormat(date, "mm.d.yyyy")}</span>
            </div>
            
            <div dangerouslySetInnerHTML={{ __html: content.rendered }} />
            <div>{tagsData.map(tag => <span key={tag.id}>#{tag.slug} </span>)}</div>
            {/* <div className="card-img-top cover-img">
                <img src={`${thumbnail}`} className="img-fluid img-fit rounded" alt={cleanTitle(title)}></img>
            </div>
            <div className="card-body p-0">
              <a className='text-decoration-none' target="_blank" rel="noopener" href={`${link}`}>
                <h5 className='card-title fw-light'>{cleanTitle(title)} <FiArrowRight /></h5>
              </a>
              <p className='card-text text-light'>{truncateText(toText(content), 0, 100) + "..."}</p>
            </div> */}
            <div className='mt-3'>
              {props.prev !== "" ? <Link to={`/blog/${props.prev}`} replace className='float-start'><FiArrowLeft /> Prev</Link> : ""}
              {props.next !== "" ? <Link to={`/blog/${props.next}`} replace className='float-end'>Next <FiArrowRight /></Link> : ""}
            </div>
      </div>
    );
}

export default Post;