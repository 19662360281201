import React, { useState } from "react"

const FORM_ENDPOINT = "https://public.herotofu.com/v1/682b8040-e30f-11ee-8980-3397320e035b";

const ContactForm = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };
  var btnColor = "btn-light";
  if (props.bg) {
    btnColor = "btn-dark";
  }

  if (submitted) {
    return (
      <>
        <div className="text-2xl">Thank you!</div>
        <div className="text-md">We'll be in touch soon.</div>
      </>
    );
  }
  return (
    <form className="form-sm text-dark" action={FORM_ENDPOINT}
        onSubmit={handleSubmit}
        method="POST"
        target="_blank">
        <div className="form-floating mb-3">
            <input type="text" className="form-control" id="name" name="name" aria-describedby="Name" placeholder="Name"/>
            <label htmlFor="name">Name</label>
        </div>
        <div className="form-floating mb-3">
            <input type="email" className="form-control" id="email" name="email" aria-describedby="Email" placeholder="Email"/>
            <label htmlFor="email">Email</label>
        </div>
        <div className="form-floating mb-3">
            <input type="phone" className="form-control" id="phone" name="phone" aria-describedby="Phone" placeholder="Phone"/>
            <label htmlFor="phone">Phone</label>
        </div>
        <div className="form-floating mb-3">
            <textarea className="form-control" id="inquiry" name="inquiry" rows="3" placeholder="Inquiry" style={{height: "100px"}}></textarea>
            <label htmlFor="inquiry">Inquiry</label>
        </div>
        <button type="submit" className={'btn btn-main ' + `${btnColor}` } >Submit</button>
    </form>
  )
}

export default ContactForm
