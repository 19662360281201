import React from "react"
import { PopupButton } from "react-calendly"
import { Row, Col } from 'react-bootstrap'
import { NavLink} from 'react-router-dom'
import ContactForm from './ContactForm'
import { FaPhoneAlt } from 'react-icons/fa'

const Contact = () => {
  document.title = "Contact | Reavis Digital";
  return (
    <Row className='hero'>
      <Col>
        <Row>
          <h1 className='section-label text-gradient'>Get in touch</h1>
        </Row>
        <Row>
            <div className="col-md-6 text-light">
                <p className="lead text-white">We exist to make you money.</p>
                <p className="lead">Let us tranform your business with online leads.</p>
                <p id="contact-text">
                    To discuss working together, you can <PopupButton 
                    className="link button-link" url="https://calendly.com/timothyreavis/15-minute-meeting"
                    rootElement={document.getElementById("root")}
                    text="schedule a virtual meeting" />, tap below to call now, or enter your contact information in the provided form.
                </p>
                <p>
                    Be sure to share as many details as possible on what you're trying to achieve and the sort of budget you have in place.
                </p>
                  <NavLink className='my-4 margin-auto btn btn-light btn-main btn-contact d-flex justify-content-center glow' to="tel:4782623557"><span className='fs-5 me-2 text-light'><FaPhoneAlt className="glow" /></span><span>  (478) 262-3557</span></NavLink>
            </div>
            <div className="col-md-6">
                <ContactForm bg={false} />
            </div>
        </Row>
      </Col>
    </Row>
  )
}

export default Contact
