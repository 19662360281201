import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from './components/Home'
import Blog from './components/Blog'
import Work from './components/Work'
import Services from './components/Services'
import Contact from './components/Contact'
import BusinessCard from './components/BusinessCard'
import Locations from './components/Locations'
import Success from './components/Success'

const wpAPI = "https://reavisdigital.com/wp-json/wp/v2/";
const dribbbleAPI = "https://api.dribbble.com/v2/user/shots?access_token=2222e8c45485db560726765ca7722923887650b6f2c4b2f69389499db1c12cf5&page=1&per_page=10";

const states = [ { short: "AZ", full: "Arizona" }, { short: "AL", full: "Alabama" }, { short: "GA", full: "Georgia" }];

const locations = [ 
    { city: "Camp Verde", state: states[0] }, 
    { city: "Cave Creek", state: states[0] }, 
    { city: "Cottonwood", state: states[0] }, 
    { city: "Flagstaff", state: states[0] }, 
    { city: "Glendale", state: states[0] },
    { city: "Phoenix", state: states[0] },
    { city: "Prescott", state: states[0] },
    { city: "Prescott Valley", state: states[0] },
    { city: "Mesa", state: states[0] },
    { city: "Tempe", state: states[0] },
    { city: "Sedona", state: states[0] },
    { city: "Surprise", state: states[0] },
    { city: "Macon", state: states[2] },
    { city: "Warner Robins", state: states[2] },
    { city: "Byron", state: states[2] },
    { city: "Mobile", state: states[1] },
];

const AppRoutes = () => {
	return (
		<Routes>
      <Route index element={<Home dribbbleAPI={dribbbleAPI} />} />
      <Route path="/services" element={<Services />} />
      <Route path="/work" element={<Work api={dribbbleAPI} />} />
      <Route path="/blog" element={<Blog api={wpAPI} />} />
      <Route path="/blog/:slug" element={<Blog api={wpAPI} />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/card" element={<BusinessCard />} />
      <Route path="/success" element={<Success />} />
      {/* donut modify else broken links, add another route instead with new slug */}
      {locations.map(route => ( <Route exact={route.exact||false} path={"/locations/website-marketing-service-" + route.city.replace(/\s+/g, '-').toLowerCase() + "-" + route.state.short.replace(/\s+/g, '-').toLowerCase()} element={<Locations city={route.city} state={route.state} service={"Web Marketing"}  />} /> ))}
      {locations.map(route => ( <Route exact={route.exact||false} path={"/locations/web-design-" + route.city.replace(/\s+/g, '-').toLowerCase() + "-" + route.state.short.replace(/\s+/g, '-').toLowerCase()} element={<Locations city={route.city} state={route.state} service={"Web Design"}  />} /> ))}
      {locations.map(route => ( <Route exact={route.exact||false} path={"/locations/seo-service-in-" + route.city.replace(/\s+/g, '-').toLowerCase() + "-" + route.state.short.replace(/\s+/g, '-').toLowerCase()} element={<Locations city={route.city} state={route.state} service={"SEO"} />} /> ))}
      {locations.map(route => ( <Route exact={route.exact||false} path={"/locations/digital-marketing-" + route.city.replace(/\s+/g, '-').toLowerCase() + "-" + route.state.short.replace(/\s+/g, '-').toLowerCase()} element={<Locations city={route.city} state={route.state} service={"Digital Marketing"} />} /> ))}
		</Routes>
	);
};

export default AppRoutes;