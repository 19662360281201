import React from "react";
import { NavLink} from 'react-router-dom'
import { Row, Col, Image, Container } from 'react-bootstrap'
import { FaEnvelope } from 'react-icons/fa'
import { FaPhoneAlt } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'
import { FaGraduationCap } from 'react-icons/fa'
import { FaLocationArrow } from 'react-icons/fa'
import { FaCloudDownloadAlt } from 'react-icons/fa'

function BusinessCard() {
  document.title = "Business Card | Reavis Digital";
  return (
    <div>
      <Row className="hero">
        <Col xs={12} className="">
          <div className="overflow-auto">
            <Image roundedCircle className="profile-pic" src="/timothy-reavis.jpeg" />
            <h1 className='section-label text-gradient fs-1'>Timothy Reavis</h1>
            <p className='lead fw-semibold'>
              Founder // Reavis Digital
            </p>
            <p className='lead'>We build online marketing solutions that deliver leads to local service businesses.</p>
            <p className='lead'>
              <FaLocationArrow /> <span className="icon-align">Prescott, Arizona</span>
            </p>
            <p className='lead'>
              <FaGraduationCap /> <span className="icon-align">Bachelor of Science // MGSU</span>
            </p>
          </div>
          <Container>
            <a className='btn btn-dark btn-main' href="/timothyreavis.vcf">
              <span><FaCloudDownloadAlt className="fs-3 me-1" /> <span className="icon-align">Digital Contact</span></span>
            </a>
          </Container>
        </Col>
      </Row>
      <Row className='section'>
      </Row>
      <Row className="section">
        <Col xs={12} lg={4}>
        <h1 className='section-label text-gradient'>Email</h1>
          <p className='lead'><a href="mailto:timothy@reavisdigital.com"><FaEnvelope /> <span className="icon-align">timothy@reavisdigital.com</span></a></p>
        </Col>
        <Col xs={12} lg={4}>
        <h1 className='section-label text-gradient'>Phone</h1>
          <p className='lead'><a href="tel:4782623557"><FaPhoneAlt /> <span className="icon-align">(478) 262-3557</span></a></p>
        </Col>
        <Col xs={12} lg={4}>
        <h1 className='section-label text-gradient'>LinkedIn</h1>
          <p className='lead'><a href="https://linkedin.com/in/timothyreavis"><FaLinkedin /> <span className="icon-align">Timothy Reavis</span></a></p>
        </Col>
      </Row>
      <Row className="hero">
        <Col>
          <h1 className='section-label text-gradient'>Portfolio</h1>
          <p className='lead'>Curious about the work I do? See what my team and I have accomplished together.</p>
          <NavLink className='btn btn-dark btn-main' to="/contact">Our Achievements</NavLink>
        </Col>
      </Row>
    </div>
  );
}

export default BusinessCard;
