import React, { useState, useEffect } from 'react';
import Post from './Post';
import PostPreview from './PostPreview';
import { useParams } from 'react-router-dom';

const Blog = (props) => {
    const { slug } = useParams();
    // const mediumUrl = "https://medium.com/feed/@joshuagauthreaux"
    // https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40uxmisfit
    const [posts, setPosts] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    var prev = ""
    var next = ""
    useEffect(() => {
        fetch(props.api + "posts")
            .then(response => response.json())
            .then(data => {
                setPosts(data)
                setIsLoading(false)
        })
    }, [props.api])

    var post = posts[0]
        
    if (typeof slug != "undefined") {
        post = posts.find(post => post.slug === slug)
    }
    const index = posts.findIndex(p => p.slug === post.slug);
        
    if (typeof posts[index-1] != "undefined") {
        prev = posts[index-1].slug
    }
    if (typeof posts[index+1] != "undefined") {
        next = posts[index+1].slug
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }
    return (
        <div className='row mb-4'>
            <div className='col-md-8'>
                <Post key={post.id} blogData={post} api={props.api} prev={prev} next={next} />
            </div>
            <div className='col-md-4'>
                <div className='hero'>
                {posts.map((post, i) => 
                    <PostPreview key={i} blogData={post} />
                )}
                </div>
            </div>
        </div>
    )
}

export default Blog;