import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom'

const PostPreview = (props) => {
    const { title, link, slug, thumbnail, content, excerpt, date } = props.blogData;

    function blogPost() {
        return <div className='mb-3'>
          <Link to={`/blog/${slug}`} replace className="text-light">
            <div className="ms-2 me-auto">
              <div className="fw-bold">{title.rendered}</div>
              <div className="small text-secondary">{dateFormat(date, "mm.d.yyyy")}</div>
              <div>{htmlToText(excerpt.rendered)}</div>
            </div>
          </Link>
            {/* <div className="card-img-top cover-img">
                <img src={`${thumbnail}`} className="img-fluid img-fit rounded" alt={cleanTitle(title)}></img>
            </div>
            <div className="card-body p-0">
              <a className='text-decoration-none' target="_blank" rel="noopener" href={`${link}`}>
                <h5 className='card-title fw-light'>{cleanTitle(title)} <FiArrowRight /></h5>
              </a>
              <p className='card-text text-light'>{truncateText(toText(content), 0, 100) + "..."}</p>
            </div> */}
          </div>
    }

    function truncateText(text, start, len) {
        return text.length > len ? text.slice(start, len) : text;
    }

    function htmlToText(block) {
        let tag = document.createElement('div');
        tag.innerHTML = block;
        block = tag.innerText;
        return block
    }

    function convertDate(date) {
        let dateArray = date.slice(0, 10).split("-")
        let year = dateArray.shift();
        dateArray.push(year)
        return `${dateArray.join(".")}`;
    }

    return (
      <div className=''>
        <div className="">
            {blogPost()}
        </div>
      </div>
    );
}

export default PostPreview;