import React from 'react';
import { Container } from 'react-bootstrap'
import { NavLink } from "react-router-dom"

const FooterComponent = ({links}) => {
  return (
    <Container className='text-center mb-4'>
      <div className="navbar navbar-expand navbar-dark bg-dark p-0">
        <ul className="navbar-nav m-auto">
          {links.map((link, i) => (
            <React.Fragment key={i}>
            <li  className="nav-item">
              <NavLink className='nav-link' to={link.to} end>
                {link.name}
              </NavLink>
            </li>
            {i < links.length - 1 && <li className="nav-item"><span className='slashes'>//</span></li>}
            </React.Fragment>
          ))}
        </ul>
      </div>
      <p className='text-muted'>&#169; {(new Date().getFullYear())} // All rights reserved.</p>
    </Container>
  )
}

export default FooterComponent
