import React from "react";
import { NavLink} from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import { FaDraftingCompass, FaSearchDollar, FaAd, FaServer, FaAddressCard, FaCamera, FaCommentDollar, FaPoll, FaPeopleArrows, FaHandshake } from 'react-icons/fa'

const services = [
  { title: "Web Design",
    description: "Transform your online presence with visually stunning and highly functional websites. Whether you need a basic site or a fully customized solution, we've got you covered.",
    icon: <FaDraftingCompass /> },
  { title: "Search Optimization",
    description: "Our web design services go beyond aesthetics. We integrate SEO best practices into the solution, ensuring that your website not only captivates visitors but also ranks high on Google.",
    icon: <FaSearchDollar /> },
  { title: "Ad Campaigns",
    description: "We'll advertise to your local market to make sure you're the business they choose when they need your services. Get ahead of the competition when it matters most.",
    icon: <FaAd /> },
  { title: "Web Hosting",
    description: "Enjoy hassle-free hosting and regular maintenance to keep your website running smoothly. Once we design and build your website, we'll deploy and host it so you can focus on the leads.",
    icon: <FaServer /> },
  { title: "Business Profiles",
    description: "Optimize your Google Business Profile to enhance local search visibility. We manage your profile, ensuring accurate information and positive customer engagement.",
    icon: <FaAddressCard /> },
  { title: "Local Photography",
    description: "Need facility photos or staff headshots? We offer business photography for our clients that are local to central Arizona. New photos would look great on your website, too!",
    icon: <FaCamera /> },
];
  const why = [
    { title: "End-to-End Marketing Solutions",
      description: "We take care of your online marketing strategy so you can focus on what you do best—serving your customers. From initial design to lead generation, we've got you covered.",
      icon: <FaCommentDollar /> },
    { title: "Proven Results",
      description: "Our clients experience a massive uptick in new leads per month, translating into substantial monthly revenue. Let us turn clicks into customers for your business.",
      icon: <FaPoll /> },
    { title: "Local Expertise",
      description: "We understand the unique needs of local businesses serving local customers. Benefit from keywords that drive high traffic and low competition in your primary service areas.",
      icon: <FaPeopleArrows /> },
    { title: "Long-Term Partnerships",
      description: "Unlike one-and-done solutions, we're committed to building lasting relationships with our clients. Your success is our success, and we're here for the long haul.",
      icon: <FaHandshake /> }
];
function Services() {
  document.title = "Marketing Services | Reavis Digital";
  return (
    <div>
      <Row className="hero">
        <Col xs={12}>
          <h1 className='section-label text-gradient'>Marketing Services</h1>
          <p className='lead'>
            Reavis Digital specializes in bringing businesses online for the first time as well as optimizing the presence of brands that have already taken the steps to digitize.</p>
            <p>Are you a local home services business looking to elevate your online presence and boost your leads? At Reavis Digital, we specialize in comprehensive web marketing services tailored for businesses just like yours.
          </p>
          <Row className='my-2'>
          {services.map((service, i) => (
            <Col key={i} xl={4} lg={6} className='py-0 px-2 d-flex align-items-stretch my-2'>
              <Card className='border-0 drop-shadow rounded-4'>
                <Card.Body className="mobile-px-0">
                  <Card.Title className='text-gradient fw-bold'><span className='fs-4 me-2 text-light'>{service.icon}</span> {service.title}</Card.Title>
                  <Card.Text>
                    {service.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
          </Row>
          <NavLink className='btn btn-light btn-main glow' to="/contact">Free Consultation</NavLink>
        </Col>
      </Row>
      <Row className="section">
        <Col xs={12}>
          <h1 className='section-label text-gradient'>Why Choose Us?</h1>
          <p className='lead'>
            There's a lot of noise designed to get the attention of business owners looking to market their services. 
            From do-it-yourself solutions that often waste your time and money and only yield poor results to one-and-done agencies that don't care about your long-term success, we're here to help you navigate past all of it.
          </p>
          
          <Row className='my-2'>
          {why.map((y, i) => (
            <Col key={i} xl={6} lg={6} className='py-0 px-2 d-flex align-items-stretch my-2'>
              <Card className='border-0 drop-shadow rounded-4 bg-light'>
                <Card.Body>
                  <Card.Title className='text-gradient fw-bold'><span className='fs-4 me-2 text-light'>{y.icon}</span> {y.title}</Card.Title>
                  <Card.Text>
                    {y.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
          </Row>
        </Col>
      </Row>
      <Row className="hero">
        <Col>
          <h1 className='section-label text-gradient'>Get Started</h1>
          <p className='lead'>Ready to transform your online presence?</p>
          <p className="lead">Take the first step toward boosting your leads and revenue. Contact us today to discuss how Reavis Digital can tailor a web marketing strategy to elevate your local home services business.</p>
          <NavLink className='btn btn-light btn-main glow' to="/contact">Reach Out</NavLink>
        </Col>
      </Row>
    </div>
  );
}

export default Services;
