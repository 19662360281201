import { NavLink} from 'react-router-dom'

const Project = (props) => {
    const { title, images } = props.WorkData;

    function project() {
        return <div className="col-md-4">
                    <NavLink to="/work">
                    <div className="card border-0 mb-3 drop-shadow bg-light">
                        <img src={`${images.two_x}`} className="img-fluid rounded-top" alt={cleanTitle(title)}></img>
                        <h5 className='card-title m-3 item-label'>{cleanTitle(title)}</h5>
                    </div>
                    </NavLink>
                </div>
    }

    function cleanTitle(checkTitle) {
        checkTitle = checkTitle.replace("amp;", "");
        return checkTitle
    }

    return (
        project()
    );
}

export default Project;