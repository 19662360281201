import React from "react"
import { PopupButton } from "react-calendly"
import { Row } from 'react-bootstrap'
import { NavLink} from 'react-router-dom'
import ContactForm from './ContactForm'
import { FaPhoneAlt } from 'react-icons/fa'

const Success = () => {
  document.title = "Contact | Reavis Digital";
  return (
    <Row className='section hero'>
      <div className="row">
        <h1 className='section-label text-gradient'>Got in touch</h1>
      </div>
      <div className="row">
          <div className="col-md-6 text-light">
              <p>Submission successful.</p>
              <p>Congratulations on taking the first step to tranform your home services business into an online lead magnet!</p>
              <p id="contact-text">
                  You can can also <PopupButton 
                  className="link button-link" url="https://calendly.com/timothyreavis/15-minute-meeting"
                  rootElement={document.getElementById("root")}
                  text="schedule a meeting with our team" /> or tap the button below to call us today.
              </p>
              <NavLink className='my-4 btn btn-dark btn-contact d-flex justify-content-center' to="tel:4782623557"><span className='fs-5 me-2 text-light'><FaPhoneAlt /></span><span>  (478) 262-3557</span></NavLink>
          </div>
          <div className="col-md-6">
              <ContactForm />
          </div>
      </div>
    </Row>
  )
}

export default Success
