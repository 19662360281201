import React, { useState, useEffect } from 'react';
import Project from './Project';
import ProjectPreview from './ProjectPreview';
import { Row } from 'react-bootstrap'
import { NavLink} from 'react-router-dom'

const Work = (props) => {
    // const mediumUrl = "https://medium.com/feed/@joshuagauthreaux"
    const [myWork, setMyWork] = useState([]);

    useEffect(() => {
        fetch(props.api)
            .then(res => res.json())
            .then(data => {
                setMyWork(data)
            })
    }, [props.api]);

    function displaySnippets() {
        return <Row> {myWork.slice(0, 3).map(work => {
                return <ProjectPreview key={work.id} WorkData={work} />
            })}</Row>
    }

    function displayWork() {
        if (props.Location === "home") {
            document.title = "Reavis Digital | Website Design, SEO, Ads & Online Marketing Services";
            return displaySnippets()
        } else {
            document.title = "Portfolio | Reavis Digital";
            return <>
            <Row className='hero'>
                <h2 className='section-label text-gradient'>Our Portfolio</h2>
                <p className='lead'>
                    Reavis Digital is an end-to-end web marketing solutions agency. 
                    We develop strategies that take you through the initial website development process all the way to marketing your business to potential clients and everything in between. 
                </p>
                <p className='lead'>
                    Check out some of our projects.
                </p>
                <NavLink className='btn btn-light btn-main glow' to="/contact">Start Your Own</NavLink>
            </Row>
            <Row className="section">
                {myWork.map(work => {
                    return <Project key={work.id} WorkData={work} />
                })}
            </Row>
            <Row className='hero'>
                <h2 className='section-label text-gradient'>Your Name Here</h2>
                <p className='lead'>
                    Like what you see? We can help your brand grow, too.
                </p>
                <NavLink className='btn btn-light btn-main glow' to="/contact">Let's talk business</NavLink>
            </Row>
            </>
        }
    }

    return (
        displayWork()
        
    );
}

export default Work;