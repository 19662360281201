import React from "react";
import { NavLink } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import { FaDraftingCompass, FaSearchDollar, FaAd, FaServer, FaAddressCard, FaCamera, FaCommentDollar, FaPoll, FaPeopleArrows, FaHandshake, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'
import ContactForm from './ContactForm'
import { PopupButton } from "react-calendly"

const services = [
  {
    title: "Web Design",
    description: "First, we transform your online presence with a visually stunning and highly optimized website. Whether you need a basic site or a fully customized solution, we've got you covered.",
    icon: <FaDraftingCompass />
  },
  {
    title: "Search Optimization",
    description: "Our web design goes beyond aesthetics. We integrate SEO essentials into the solution, helping your business be the first thing customers see when Googling your industry.",
    icon: <FaSearchDollar />
  },
  {
    title: "Ad Campaigns",
    description: "We also manage campaigns advertising to your local market, ensuring your business is the one they choose when your services are needed. Get ahead of the competition when it matters most.",
    icon: <FaAd />
  },
  {
    title: "Web Hosting",
    description: "Once finalized, we'll deploy and host your website so you can focus on incoming leads. Enjoy hassle-free hosting and maintenance to keep your website running smoothly.",
    icon: <FaServer />
  },
  {
    title: "Business Profiles",
    description: "Next, we optimize your Google Business Profile to enhance local search visibility. We'll manage your profile to ensure accurate information and foster positive customer engagement.",
    icon: <FaAddressCard />
  },
  {
    title: "Local Photography",
    description: "Need facility photos or staff headshots? We offer business photography for our clients that are local to central Arizona. Then we'll make sure the new photos look great on your new website!",
    icon: <FaCamera />
  },
];

const why = [
  {
    title: "End-to-End Marketing Solutions",
    description: "We take care of your entire marketing strategy, so you can focus on what you do best—serving your clients. From initial design to lead generation, we've got you covered.",
    icon: <FaCommentDollar />
  },
  {
    title: "Proven Results",
    description: "Our clients experience a massive uptick in new leads per month, translating into substantial monthly revenue reaching tens of thousands of dollars. Let us turn clicks into customers for your home services business.",
    icon: <FaPoll />
  },
  {
    title: "Local Expertise",
    description: "We understand the unique needs of local businesses serving local customers. Benefit from keywords that drive high traffic and low competition in your primary service areas.",
    icon: <FaPeopleArrows />
  },
  {
    title: "Long-Term Partnerships",
    description: "Unlike one-and-done solutions, we're committed to building lasting relationships with our clients. Your success is our success, and we're here for the long haul.",
    icon: <FaHandshake />
  }
];

function Locations(props) {
  document.title = `${props.service} Services in ${props.city}, ${props.state.short}`;
  return (
    <div>
      <Row className="hero">
        <Col xs={12}>
          <span className="text-gradient-long">
            <h1 className='fs-1'>{props.service} Services in {props.city}, {props.state.full}</h1>
          </span>
          <h2 className='fw-light fs-4 my-4'>
            We specialize in bringing {props.city} businesses online and optimizing brands that have already taken the steps to digitize.
          </h2>
          <p>
            Want to make more money for your local {props.city}, {props.state.short} business? We deliver a comprehensive {props.service} services package designed to boost your leads through the roof.
          </p>
          <p className="text-white">
            Get in touch for a free consultation.
          </p>
          <Row className='my-2'>
            <Col xl={6} lg={6} className='py-0 px-2 d-flex align-items-stretch my-2'>
              <NavLink className='btn btn-light btn-main btn-contact d-flex justify-content-center' to="tel:4782623557"><span className='fs-5 me-2 text-light'><FaPhoneAlt /></span><span>  (478) 262-3557</span></NavLink>
            </Col>
            <Col xl={6} lg={6} className='py-0 px-2 d-flex align-items-stretch my-2'>
              <NavLink className='btn btn-light btn-main glow' to="/contact">Free Consultation</NavLink>
            </Col>
          </Row>
          <Row className='my-2'>
          {services.map((service, i) => (
            <Col key={i} xl={4} lg={6} className='py-0 px-2 d-flex align-items-stretch my-2'>
              <Card className='border-0 drop-shadow rounded-4'>
                <Card.Body className="mobile-px-0">
                  <Card.Title className='text-gradient fw-bold'><span className='fs-4 me-2 text-light'>{service.icon}</span> {service.title}</Card.Title>
                  <Card.Text>
                    {service.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
          </Row>
        </Col>
      </Row>
      <Row className="section">
      <div className="row">
        <div className="row">
          <h1 className='section-label text-gradient'>Get in touch</h1>
        </div>
        <div className="col-md-6 text-light">
            <p className="lead text-white">We exist to make you money.</p>
            <p className="lead">Let us tranform your business with online leads.</p>
            <p id="contact-text">
                For a free consultation, you can <PopupButton 
                className="link button-link" url="https://calendly.com/timothyreavis/15-minute-meeting"
                rootElement={document.getElementById("root")}
                text="schedule a virtual meeting" /> or enter your contact information in the provided form.
            </p>
            <p>
                Be sure to share as many details as possible on what you're trying to achieve and the sort of budget you have in place.
            </p>
        </div>
        <div className="col-md-6">
            <ContactForm bg={true} />
        </div>
      </div>
      </Row>
      <Row className="section">
        <Col xs={12}>
          <h1 className='section-label text-gradient'>Why Choose Us?</h1>
          <p className='lead'>
            There's a lot of noise designed to get the attention of business owners looking to market their services. From DIY solutions that can waste your precious time and money while only yielding poor results to one-and-done agencies that don't care about your long-term success, we're here to help you navigate past all that.
          </p>
          <p className='lead'>
            <span className="text-white">Our job is to make you money.</span> We do this by helping your business be the first thing potential clients see when they search for your services. And we'll work with you for the long haul to make sure it stays that way.
          </p>
          <Row className='my-2'>
          {why.map((y, i) => (
            <Col key={i} xl={6} lg={6} className='py-0 px-2 d-flex align-items-stretch my-2'>
              <Card className='border-0 drop-shadow rounded-4 bg-light'>
                <Card.Body>
                  <Card.Title className='text-gradient fw-bold'><span className='fs-4 me-2 text-light'>{y.icon}</span> {y.title}</Card.Title>
                  <Card.Text>
                    {y.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
          </Row>
        </Col>
      </Row>
      <Row className="hero">
        <Col>
          <h1 className='section-label text-gradient'>Get Started</h1>
          <p className='lead'>Ready to transform your online presence?</p>
          <p className="lead">Take the first step toward boosting your leads and revenue. Contact us today to discuss how Reavis Digital can tailor a web marketing strategy to elevate your local home services business.</p>
          <NavLink className='btn btn-light btn-main glow' to="/contact">Reach Out</NavLink>
        </Col>
      </Row>
    </div>
  )
}

export default Locations;
